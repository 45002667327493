import { Component } from '@angular/core';
import { NavigationEnd, ActivatedRoute, Router } from '@angular/router';

import { HttpService } from './services/http.service'
import { SEOService } from './services/seo.service';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [HttpService, SEOService],
})
export class AppComponent {

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private _seoService: SEOService) {
    console.log = function (): void {};
  }

  ngOnInit() {
    this.router.events.pipe(
       filter((event) => event instanceof NavigationEnd),
       map(() => this.activatedRoute),
       map((route) => {
         while (route.firstChild) route = route.firstChild;
         return route;
       }),
       filter((route) => route.outlet === 'primary'),
       mergeMap((route) => route.data)
      )
      .subscribe((event: any) => {
        this._seoService.updateTitle(event['title']);
        // this._seoService.updateOgUrl(event['ogUrl']);
        this._seoService.updateDescription(event['description'])
      }); 
    }
}
