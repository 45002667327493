import { Component, OnInit, inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { HttpService } from '../services/http.service'

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit{
  private httpService = inject(HttpService);

  termsAndConditionsUrl = $localize`/assets/docs/algemene_voorwaarden.pdf`;

  persons = 1;
  private maxPersons = 4;
  price = 0;
  availability: any = null;
  private maxSpotsTotal = 24;

  private monthNames = [$localize`januari`, $localize`februari`, $localize`maart`, $localize`april`, $localize`mei`, $localize`juni`, $localize`juli`, $localize`augustus`, $localize`september`, $localize`oktober`, $localize`november`, $localize`december`];

  transfers:any = []
  choosenTransfer: any | null = null

  name: string = ""
  email: string = ""
  phone: string = ""
  address: string = ""
  city: string = ""
  postal: string = ""

  termsAccepted = false

  public window = window;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    if(this.route.snapshot.paramMap.get('id') == (null || undefined)) {
      this.home()
      return
    }

    this.getAvailability();
    this.persons = this.getAmountOfPeople()
    // this.getTransfers();
  }

  transferChange(i:number) {
    this.choosenTransfer = this.choosenTransfer == this.transfers[i] ? null : this.transfers[i]
  }

  getAvailability() {
    let id = this.getAvailabilityId()
    console.log(id)
      this.httpService.getAvailabilityDetail(id).subscribe(res => {
        console.log(res)
        this.availability = res;
        this.price = this.availability.price;
        this.maxPersons = this.availability.bookings < (this.maxSpotsTotal-this.maxPersons) ? this.maxPersons : this.maxSpotsTotal - this.availability.bookings;
        if (this.maxPersons == 0 ) {this.home();}
      });
  }

  getAvailabilityId (): string {
    return this.route.snapshot.paramMap.get('id')!!.slice(2)
  }

  getAmountOfPeople (): number {
    return parseInt(this.route.snapshot.paramMap.get('id')!![0])
  }

  getTransfers() {
    this.httpService.getTransfers().subscribe(res => this.transfers = res)
  }

  onPayClick() {
    let availabilityJson = {id: this.getAvailabilityId(), start: this.availability.start, end: this.availability.end}

    this.httpService.book(this.name, this.email, this.phone, this.address, this.city, this.postal, this.persons, availabilityJson, this.choosenTransfer?.id)
      .subscribe(res => {
        console.log(res)
        window.location.href = res.toString()
      })
  }

  onMinusClick() {
    if(this.persons < 2) return;
    this.persons--
  }

  onPlusClick() {
    if(this.persons >= this.maxPersons) return;
    this.persons++
  }

  getDateLabel() {
    if (this.availability == null) return;
    let startDay = this.availability.start[0]+this.availability.start[1]
    let startMonth = this.availability.start[3]+this.availability.start[4]
    let endDay = this.availability.end[0]+this.availability.end[1]
    let endMonth = this.availability.end[3]+this.availability.end[4]
    return startDay + " " + this.monthNames[parseInt(startMonth)-1] + " t/m " + endDay + " " + this.monthNames[parseInt(endMonth)-1] 
  }

  formCompleted() {
   return this.nameChecker() && this.emailChecker() && this.phoneChecker() && this.addressChecker() && this.cityChecker() && this.postalChecker() && this.termsAccepted
  }

  nameChecker() {
    return this.name.length > 2 ? true : false
  }

  emailChecker() {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(this.email)
  }

  phoneChecker() {
    return this.phone.length >= 8
  }

  addressChecker() {
    return this.address.length >= 3
  }

  cityChecker() {
    return this.city.length >= 3
  }

  postalChecker() {
    return this.postal.length >= 3
  }

  home() {
    this.router.navigate([''])
  }
}
