import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BookingComponent } from './booking/booking.component';
import { HomeComponent } from './home/home.component';
import { OrderComponent } from './order/order.component';
import { ContactComponent } from './contact/contact.component';
import { LicensesComponent } from './licenses/licenses.component';
import { CrewComponent } from './crew/crew.component';
import { PioneerComponent } from './pioneer/pioneer.component';
import { SurfComponent } from './surf/surf.component';
import { ExploreComponent } from './explore/explore.component';
import { CampComponent } from './camp/camp.component';
import { EenSupertofNieuwSurfkampInPortugalComponent } from './blog/een-supertof-nieuw-surfkamp-in-portugal/een-supertof-nieuw-surfkamp-in-portugal.component';

const TitleMain = $localize`FERRO: Hét ruigste surf camp van Portugal`;
const DescriptionMain = $localize`Het ruigste en nieuwste surfkamp van Portugal. Hier maak jij kennis met het rauwe, authentieke Portugal, op een plek die we met veel liefde en aandacht hebben opgezet.`

const TitleBook = $localize`Je bent er bijna! | Ferro Surf Camp`;
const TitleOrderStatus = $localize`Op naar Portugal | Ferro Surf Camp`;

const TitlePioneer = $localize`Waar surfen het lokale portugal ontmoet | Ferro Surf Camp`;
const DescriptionPioneer = $localize`Ontdek het vernieuwende Ferro Surf Camp`;

const TitleSurf = $localize`Elk niveau surfskills, bij de beste surfschool van de regio | Ferro Surf Camp`;
const DescriptionSurf = $localize`Ontdek hoe jij een echte surfheld wordt`;

const TitleExplore = $localize`Een regio naar ons hart: de Alentejo | Ferro Surf Camp`;
const DescriptionExplore = $localize`Ontdek het rauwe Portuguese leven in de Alentejo`;

const TitleCamp = $localize`Goed slapen, heerlijk eten en kleinschalig | Ferro Surf Camp`;
const DescriptionCamp = $localize`Ontdek het luxe maar ruige tintje van base camp`;

const TitleCrew = $localize`Met wie ga je mee? | Ferro Surf Camp`;
const DescriptionCrew = $localize`Ontdek met wie je op reis gaat`;

const TitleContact = $localize`Whatsapp, Insta of mail | Ferro Surf Camp`;
const DescriptionContact = $localize`Neem contact met ons op via whatsapp`;

const TitleLicenties = $localize`Licenties | Ferro Surf Camp`;
const DescriptionLicenties = $localize`Hier vind je een lijst van licenties die de website gebruikt`;

const routes: Routes = [
  { 
    path: '', 
    component: HomeComponent, 
    data: {
      title: TitleMain,
      description: DescriptionMain,
      ogUrl: ''
    }  
  },
  { 
    path: 'book/:id', 
    component: BookingComponent,
    data: {
      title: TitleBook,
      description:'',
      ogUrl: ''
    } 
  },
  { 
    path: 'order/:id', 
    component: OrderComponent,
    data: {
      title: TitleOrderStatus,
      description:'',
      ogUrl: ''
    }  
  },
  { 
    path: 'pioneer', 
    component: PioneerComponent,
    data: {
      title: TitlePioneer,
      description: DescriptionPioneer,
      ogUrl: ''
    }  
  },
  { 
    path: 'surf', 
    component: SurfComponent,
    data: {
      title: TitleSurf,
      description: DescriptionSurf,
      ogUrl: ''
    }  
  },
  { 
    path: 'explore', 
    component: ExploreComponent,
    data: {
      title: TitleExplore,
      description: DescriptionExplore,
      ogUrl: ''
    }  
  },
  { 
    path: 'camp', 
    component: CampComponent,
    data: {
      title: TitleCamp,
      description: DescriptionCamp,
      ogUrl: ''
    }  
  },
  { 
    path: 'crew', 
    component: CrewComponent,
    data: {
      title: TitleCrew,
      description: DescriptionCrew,
      ogUrl: ''
    }  
  },
  { 
    path: 'contact', 
    component: ContactComponent,
    data: {
      title: TitleContact,
      description: DescriptionContact,
      ogUrl: ''
    }  
  },
  { 
    path: 'blog/supertof-nieuw-surfkamp-in-de-prachtige-Alentejo-regio-in-Portugal', 
    component: EenSupertofNieuwSurfkampInPortugalComponent,
    data: {
      title: TitleMain,
      description:'Ferro Surfcamp: een supertof nieuw surfkamp in de prachtige Alentejo regio. Je vindt hier alles wat je nodig hebt: mooie stranden aan de Atlantische Oceaan, heerlijk eten en bovenal een warm welkom voor iedereen die deze relatief onbekende regio wilt ontdekken.',
      ogUrl: ''
    }  
  },
  { 
    path: 'licenses', 
    component: LicensesComponent,
    data: {
      title: TitleLicenties,
      description: DescriptionLicenties,
      ogUrl: ''
    }   
  },
  { path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {  }
