import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';

import { HttpService } from '../services/http.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  private httpService = inject(HttpService);

  @ViewChild('ferrologo') yourElement: ElementRef | undefined;
  @ViewChild('joinus') joinUsElement: ElementRef | undefined;
  @ViewChild('booknow') bookNowElement: ElementRef | undefined;

  bookNowVisible = false;
  atJoinUs = false;
  atBookNow = false;

  selectedWeek: any = null;
  weeks: any = [];

  daysOfWeek = [$localize`ma`, $localize`di`, $localize`wo`, $localize`do`, $localize`vr`, $localize`za`, $localize`zo`] //NL
  monthsOfYear = [$localize`januari`, $localize`februari`, $localize`maart`, $localize`april`, $localize`mei`, $localize`juni`, $localize`juli`, $localize`augustus`, $localize`september`, $localize`oktober`, $localize`november`, $localize`december`]
  currentMonth: number = 6
  currentYear: number = 2024

  videoSourceLandscape = "/assets/videos/ferro_landscape_lowres.mp4"
  videoSourcePortrait = "/assets/videos/ferro_portrait_extralowres.mp4"
  videoSource = this.videoSourceLandscape
  landscape = window.matchMedia("(orientation: landscape)");

  people = 1

  amountOfPeople = [1,2,3,4,5,6]
  maxPeople = 24

  foodSubtext = false
  surfSubtext = false
  staySubtext = false
  alentejoSubtext = false
  accomodationSubtext = false
  lunchSubtext = false

  public window = window;

  constructor(public router: Router) {
    this.checkVideoSource()
    this.landscape.addEventListener("change", ev => {
      this.checkVideoSource()
    });
  }

  checkVideoSource() {
    if(window.innerHeight > window.innerWidth){
      this.videoSource = this.videoSourcePortrait
    } else {
      this.videoSource = this.videoSourceLandscape
    }
  }

  daysInMonthCount(month: number, year: number) {
    return new Date(year, month, 0).getDate();
  }

  getMonthByNumber(month: number) {
    return this.monthsOfYear[month-1]
  }

  getFirstWeekdayOfMonth(month: number, year: number) {
    let firstDay = new Date(year, month-1, 1).getDay();
    let dayIndex = firstDay === 0 ? 7 : firstDay;
    return dayIndex
  }

  getNextMonth() {
    if (this.currentMonth == 12) return 1
    return this.currentMonth+1
  }

  getNextYear() {
    if (this.currentMonth == 12) return this.currentYear+1
    return this.currentYear
  }

  isStartDate(year:number, month:number, day:number) {
    let isStartDate = false;
    this.weeks?.forEach((week: { data: { start: string; }; }) => {
      let startDate = this.getDateFromWeekString(week.data.start)
      let compareDate = new Date(year, month-1, day)
    
      if (compareDate.getTime() == startDate.getTime()) {
        isStartDate = true
      }
    });

    return isStartDate;
  }

  isFullClassCheck(year:number, month:number, day:number) {
    let isFull = false;
    this.weeks?.forEach((week: { data: { start: string, bookings: number}; }) => {
      let startDate = this.getDateFromWeekString(week.data.start)
      let compareDate = new Date(year, month-1, day)
    
      if (compareDate.getTime() == startDate.getTime()) {
        if (this.isFull(week)) {
          isFull = true
        }
      }
    });

    return isFull;
  }

  isFull(week: any) {
    return (week.data.bookings + this.people) > this.maxPeople
  }

  selectOption(day:number, month:number, year:number) {
    if(!this.isStartDate(year, month, day) || this.isFullClassCheck(year, month, day)) {
      return
    } else {
      this.weeks?.forEach((week: { data: { start: string; }, id: string; }) => {
        let startDate = this.getDateFromWeekString(week.data.start)
        let compareDate = new Date(year, month-1, day)
          
        if (compareDate.getTime() == startDate.getTime()) {
          this.selectedWeek = week
        }
      });
    }
  }

  isSelected(year:number, month:number, day:number) {
    if(this.selectedWeek == null) return
    let startDate = this.getDateFromWeekString(this.selectedWeek.data.start)
    let endDate = this.getDateFromWeekString(this.selectedWeek.data.end)
    let compareDate = new Date(year, month-1, day)

    return compareDate >= startDate && compareDate <= endDate
  }

  isSelectedStart(year:number, month:number, day:number) {
    if(this.selectedWeek == null) return

    let startDate = this.getDateFromWeekString(this.selectedWeek.data.start)
    let compareDate = new Date(year, month-1, day)

    return compareDate.getTime() == startDate.getTime()
  }

  isSelectedEnd(year:number, month:number, day:number) {
    if(this.selectedWeek == null) return

    let endDate = this.getDateFromWeekString(this.selectedWeek.data.end)
    let compareDate = new Date(year, month-1, day)

    return compareDate.getTime() == endDate.getTime()
  }

  getDateFromWeekString(date:string) {
    let day = date[0]+date[1]
    let month = date[3]+date[4]
    let year = date[6]+date[7]+date[8]+date[9]

    return new Date("" + year + "-" + month + "-" + day + "T00:00:00")
  }

  changeAmountOfPeople(amount: number) {
    this.people = amount
    if (this.selectedWeek == null) return
    if (this.isFull(this.selectedWeek)) {
      this.selectedWeek = null
    }
  }

  ngOnInit() {
    this.httpService.getAvailability().subscribe(res => {
      this.weeks = res
      this.weeks.sort((a: any,b: any) => {
        return this.getDateInt(a.data.start) - this.getDateInt(b.data.start)
      })
    });
  }

  onBookClick() {
    if (this.selectedWeek == null) return;
    this.router.navigate(['/book/' + this.people + "-" + this.selectedWeek.id + "/"])
  }

  onBookHeroClick() {
    window.location.href = '/#join-us'
  }

  floatButtonClick(el: HTMLElement) {
    el.scrollIntoView();
  }

  floatEnabled(targetElement: HTMLElement) {
    return this.bookNowVisible;
  }

  isInViewport(element: HTMLElement) {
    var rect = element.getBoundingClientRect();
    var html = document.documentElement;
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || html.clientHeight) &&
      rect.right <= (window.innerWidth || html.clientWidth)
    );
  }

  ngAfterViewInit() {
    const threshold = 0.5;
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  this.bookNowVisible = false
                } else {
                  this.bookNowVisible = true;
                }
            });
        },
        { threshold }
    );
    observer.observe(this.yourElement?.nativeElement);

    // const observerJoinUs = new IntersectionObserver(
    //     (entries) => {
    //         entries.forEach((entry) => {
    //             if (entry.isIntersecting) {
    //               this.atJoinUs = true
    //             } else {
    //               this.atJoinUs = false;
    //             }
    //         });
    //     },
    //     { threshold }
    // );
    // observerJoinUs.observe(this.joinUsElement?.nativeElement);

    const observerBookNow = new IntersectionObserver(
      (entries) => {
          entries.forEach((entry) => {
              if (entry.isIntersecting) {
                this.atBookNow = true
              } else {
                this.atBookNow = false;
              }
          });
      },
      { threshold }
  );
  observerBookNow.observe(this.bookNowElement?.nativeElement);
  }

  getDateInt(date: string) {
    let day = date[0]+date[1]
    let month = date[3]+date[4]
    let year =  date[6]+date[7]+date[8]+date[9]
    return parseInt(year+month+day)
  }

  nextMonth() {
    if (this.currentMonth == 12) {
      this.currentMonth = 1
      this.currentYear++
    } else {
      this.currentMonth++
    }
  }

  previousMonth() {
    if (this.currentMonth == 1) {
      this.currentMonth = 12
      this.currentYear--
    } else {
      this.currentMonth--
    }
  }



  getPrice() {
    if (this.selectedWeek != null) {
      return this.people*this.selectedWeek.data.price
    } else {
      return "-"
    }
  }
}
