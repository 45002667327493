import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-een-supertof-nieuw-surfkamp-in-portugal',
  templateUrl: './een-supertof-nieuw-surfkamp-in-portugal.component.html',
  styleUrls: ['./een-supertof-nieuw-surfkamp-in-portugal.component.scss']
})
export class EenSupertofNieuwSurfkampInPortugalComponent {
  @ViewChild('booknow') yourElement: ElementRef | undefined;
  bookNowVisible = true;
  public window = window;

  constructor(private router: Router) {}

  logoClick() {
    this.router.navigate(['/'])
  }
}
