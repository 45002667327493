<div class="content" role="main">

    <div class="hero-image">
        <div class="logo" (click)="logoClick()">
          <img src="/assets/images/logo_clear_black-rust_no_border.png" class="hero-logo" alt="Ferro Surf Camp logo"/>
        </div>
    </div>

    <app-hamburger></app-hamburger>

    
    <div class="section-text-container">

        <div class="section-text">
            <p>Ferro Surfcamp: een supertof nieuw surfkamp in de prachtige Alentejo regio. Je vindt hier alles wat je nodig hebt: mooie stranden aan de Atlantische Oceaan, heerlijk eten en bovenal een warm welkom voor iedereen die deze relatief onbekende regio wilt ontdekken. Kom jij deze zomer ook de golven trotseren op deze bijzondere plek?
                </p>
        </div>

        <img src="/assets/images/explore.jpeg" />

        <div class="section-text">
            <p>
                Drie ingrediënten waren er nodig om deze droom in Portugal te verwezenlijken: een paar knetterenthousiaste ondernemers, een vette plek midden in de Alentejo regio (tussen Lissabon en de Algarve) én een hele succesvolle crowdfunding. Zo succesvol dat Jannah, Tristan en Wiland zich inmiddels kunnen opmaken voor hun eerste seizoen. Wiland: 'We hoopten natuurlijk dat we snel aan de slag konden, maar dat het zo snel zou gaan hadden we niet verwacht. Het zorgt voor een ontzettend positieve sfeer en we zijn nu met zijn drieën aan het knallen om de eerste gasten te verwelkomen deze zomer.'
                <br><br>
                <b>Alente.. wat?</b><br>
                Het kamp bevindt zich in de Alentejo regio, maar waarom nou deze plek?
                Tristan: 'Het bijzondere aan deze plek is dat het nog redelijk onbekend is. Ons kamp is daarom echt voor jonge mensen die naast surfen ook graag nieuwe plekken willen ontdekken. We bieden onze gasten niet alleen een kamp waar ze nieuwe gelijkgestemden ontmoeten, maar waar ze vooral de lokale bevolking leren kennen, wat Portugees opsteken en de (boeren)cultuur mee maken. Natuurlijk zorgen we daarnaast ook voor heerlijke gerechten uit de lokale keuken met een proeverij van de lokale wijnen.'
                <br><br>
                <b>Lokaal en duurzaam</b><br>
                Jannah: 'Het past bij ons: we houden zelf enorm van onontgonnen gebieden, waar alles nog niet té gepolijst is. We bouwen de verblijven met lokale bouwmaterialen, zorgen voor toiletten met een circulair watersysteem en er komt een natuurlijk zwembad. Alles om de droge zomers zo duurzaam mogelijk door te komen. Daarnaast hebben we super leuk contact met de lokale bewoners, die ons ook op weg helpen en meedenken met andere toffe activiteiten. Wiland spreekt vloeiend Portugees zodat we hier mooie samenwerkingen aan kunnen gaan. Met ons surfkamp dragen we hiermee ook ons steentje bij aan de lokale economie. Dat geeft een goed gevoel!'
                <br><br>
                <b>Golven pakken</b><br>
                Naast het feit dat je hier het echte authentieke Portugal ervaart, zijn de surfspots ook nog van hoge kwaliteit. Wiland: ' We hebben meerdere spots die geschikt zijn voor alle niveaus en alle weersomstandigheden. Dat maakt ons lekker flexibel: het toffe is dat je naast de oceaandeining ook stroming hebt van de verschillende rivieren in de regio. Dat maakt de plek interessant voor beginners, maar absoluut ook voor gevorderden. Samen met lokale instructeurs geven we zo onze gasten een onvergetelijke ervaring. 
                <br><br>
                <b>Inschrijven</b><br>
                Ben je nou enthousiast geworden van het verhaal van deze drie enthousiastelingen en heb je zin in een vakantie vol zon, strand, adrenaline en de échte Portugalervaring? Check dan de website en schrijf je in voor één van de weken in de zomer. 
                </p>
        </div>
    </div>

  <div class="booking-button-float" (click)="window.location.href = '/#book-now'">
          <button class="booking-button float-button"  id="book-button-float">
            <p id="book-button-float-text" style="padding-right: 26px;">book now <span class="material-symbols-outlined calendar-icon">
                event_upcoming
              </span></p>
          </button>
        </div>
  
    <app-footer style="width: 100%;"></app-footer>
  
  </div>