import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';

import { HttpService } from '../services/http.service'
import { pages } from '../page-selector/page-selector.component';

@Component({
  selector: 'app-pioneer',
  templateUrl: './pioneer.component.html',
  styleUrls: ['./pioneer.component.scss']
})
export class PioneerComponent {
  @ViewChild('booknow') yourElement: ElementRef | undefined;
  bookNowVisible = true;

  public window = window;
  public pages = pages;

  constructor(private router: Router) {}

  logoClick() {
    this.router.navigate(['/'])
  }
}

