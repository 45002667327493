import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  termsAndConditionsUrl = $localize`/assets/docs/algemene_voorwaarden.pdf`;
  privacyURL = $localize`/assets/docs/privacy_policy.pdf`;

}
