<div class="content" role="main">

    <div class="hero-image">
        <div class="logo" (click)="logoClick()">
          <img src="/assets/images/logo_clear_black-rust_no_border.png" class="hero-logo" alt="Ferro Surf Camp logo"/>
        </div>
    </div>

    <app-hamburger></app-hamburger>

  
    <!-- Impressions -->
    <div class="impression-section">
  
      
  
      <!-- TEAM -->
      <div class="section">
        <div class="section-text">
          <h1 i18n class="section-header-title">CREW</h1>
          <p i18n>Wie zijn wij eigenlijk? Een stelletje piraten, afgedreven naar de kust van Portugal. Wij vinden ons zelf ongefilterd, ruig en stoer, wij zijn wel de enige die dat vinden. 
            <br><br>
            Na een aantal jaar toeristen rondgevaren te hebben in onze stad, met ontzettend veel plezier, kennis hebben laten maken met een plek die ons zo goed bekend is. Wilde wij dit ook bieden in een regio die ons, naast Amsterdam, ook zeer aan het hart ligt. 
          </p>
        </div>
        <div class="section-image-container">
          <div class="section-image join-us-image">
            <h1 i18n class="section-header-right">CREW</h1>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="section-image-container">
          <div class="section-image join-us-image-2">
          </div>
        </div>
        <div class="section-text">
          <p i18n> Wij bieden mensen niet alleen een kamp waar ze nieuwe gelijkgestemden ontmoeten, maar waar ze vooral de lokale bevolking leren kennen, wat Portugees opsteken en de (boeren)cultuur mee maken. Totaal anders dan het Nederlandse leven, absoluut niet minder mooi of belangrijk. 
            <br><br>
            Waar je in Nederland soms in de anonimiteit kan verdwijnen, is dat in de Alentejo onmogelijk. Hier staan mensen klaar voor elkaar en wij dus ook voor de lokale bevolking. Dit gevoel willen we graag delen met jou. Daarnaast komt de passie voor surfen en andere buitensporten natuurlijk ook naar boven en wordt er elke dag de jacht op de beste golven geopend. 
          </p>
        </div>
      </div>

      <app-page-selector [current]="pages.pioneer"></app-page-selector>

      <app-action-bar></app-action-bar>

    <app-footer style="width: 100%;"></app-footer>
  
  </div>