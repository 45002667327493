<div class="content" role="main">

    <div class="hero-image">
        <div class="logo" (click)="logoClick()">
          <img src="/assets/images/logo.png" class="hero-logo" alt="Ferro Surf Camp logo"/>
        </div>
        <div class="hero-text-container">
          <h1 i18n class="hero-text">CONTACT</h1>
        </div>
    </div>
  
  
    <!-- Impressions -->
    <div class="contact-section">
  
      <div class="section">
        <p i18n>
            Voor vragen over het surf camp mail ons op <a href = "mailto: surf@ferrosurfcamp.nl">surf@ferrosurfcamp.nl</a>
             of stuur ons een bericht op instagram <a href = "https://instagram.com/ferrosurfcamp">@ferrosurfcamp</a>.
            </p>
            <p i18n>
                Zakelijke vragen?
                Mail naar <a href = "mailto: office@ferrosurfcamp.nl">office@ferrosurfcamp.nl</a>
            </p>
            <p i18n>
                <b>Organisatie</b><br>
                Ferro Ventures<br>
Keizerstraat 184<br>
3512eb utrecht<br>
kvk: 91917077<br>
btw: nl865816177b01 <br>
            </p>

      </div>    
    </div>
    <app-footer style="width: 100%;"></app-footer>
  </div>