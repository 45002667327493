<div class="blocks-container">
    <div class="block">
        <a href="/pioneer"><h1 i18n>pioneer</h1></a>
    </div>
    <div class="block">
        <a href="/surf"><h1 i18n>surf</h1></a>
    </div>
    <div class="block">
        <a href="/explore"><h1 i18n>explore</h1></a>
    </div>
    <div class="block">
        <a href="/camp"><h1 i18n>camp</h1></a>
    </div>
</div>