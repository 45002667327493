import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { HttpService } from './services/http.service'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { BookingComponent } from './booking/booking.component';
import { HomeComponent } from './home/home.component';
import { OrderComponent } from './order/order.component';
import { ContactComponent } from './contact/contact.component';
import { LicensesComponent } from './licenses/licenses.component';
import { SEOService } from './services/seo.service';
import { FooterComponent } from './footer/footer.component';

import { Angulartics2Module } from 'angulartics2';
import { CrewComponent } from './crew/crew.component';
import { HamburgerComponent } from './hamburger/hamburger.component';
import { PioneerComponent } from './pioneer/pioneer.component';
import { SurfComponent } from './surf/surf.component';
import { ExploreComponent } from './explore/explore.component';
import { CampComponent } from './camp/camp.component';
import { NavigationBlocksComponent } from './navigation-blocks/navigation-blocks.component';
import { EenSupertofNieuwSurfkampInPortugalComponent } from './blog/een-supertof-nieuw-surfkamp-in-portugal/een-supertof-nieuw-surfkamp-in-portugal.component';
import { ActionBarComponent } from './action-bar/action-bar.component';
import { PageSelectorComponent } from './page-selector/page-selector.component';

@NgModule({
  declarations: [
    AppComponent,
    BookingComponent,
    HomeComponent,
    OrderComponent,
    ContactComponent,
    LicensesComponent,
    FooterComponent,
    CrewComponent,
    HamburgerComponent,
    PioneerComponent,
    SurfComponent,
    ExploreComponent,
    CampComponent,
    NavigationBlocksComponent,
    EenSupertofNieuwSurfkampInPortugalComponent,
    ActionBarComponent,
    PageSelectorComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatButtonModule,
    FormsModule,
    Angulartics2Module.forRoot(),
  ],
  providers: [HttpService, SEOService],
  bootstrap: [AppComponent]
})
export class AppModule { }
