<div class="content" role="main">

    <div class="hero-image">
        <div class="logo" (click)="logoClick()">
            <img src="/assets/images/logo_clear_black-rust_no_border.png" class="hero-logo"
                alt="Ferro Surf Camp logo" />
        </div>
    </div>

    <app-hamburger></app-hamburger>

    <!-- Impressions -->
    <div class="impression-section">

        <h1 i18n style="padding: 20px; text-align: center;">elk niveau, bij de beste surfschool van de regio
        </h1>

        <!-- SURF -->
        <div class="section">
            <div class="section-text">
                <h1 i18n class="section-header-title">SURF</h1>
                <p i18n>Of je nu een absolute beginner bent die nog nooit een surfplank heeft aangeraakt of een ervaren surfer bent die op zoek is naar nieuwe uitdagingen, bij ons vind je de perfecte omgeving om je vaardigheden te verbeteren. 
                    <br><br>
                    Met vijf surflessen per week, elk ongeveer twee uur lang, bieden we een uitgebreid programma dat is afgestemd op jouw niveau en behoeften.
                </p>
            </div>
            <div class="section-image-container">
                <div class="section-image surf-image-5">
                    <h1 i18n class="section-header-right">SURF</h1>
                </div>
            </div>
        </div>

        <div class="section">
            
            <div class="section-image-container">
                <div class="section-image surf-image-2">
                </div>
            </div>
            <div class="section-text">
                <p i18n>De ervaren instructeurs, afkomstig van de beste surfschool in de regio, staan ​​klaar om je te begeleiden en te inspireren terwijl je de golven van de prachtige Alentejo-kust verkent. 
                    <br><br>
                    Van het leren van de basisprincipes tot het verfijnen van geavanceerde technieken, we bieden een veilige en ondersteunende omgeving waarin je kunt groeien als surfer. 
                </p>
            </div>
        </div>

        <div class="section">
            <div class="section-text">
                <p i18n>De surfspots bevinden zich nabij Vila Nova de Milfontes. Dit is een heel bijzonder stukje, omdat het aantal mensen wat tegelijk mag surfen streng is gereguleerd. Zo wordt ervoor gezorgd dat het nooit te druk is in het water en je dus altijd de beste golf kan pakken! 
                    
                    <br><br>
                    Door de samenwerking met de lokale surfscholen krijg je naast een instructeur met de beste kennis van de spot, ook een garantie op alle ruimte om te leren surfen. 
                </p>
            </div>
            <div class="section-image-container">
                <div class="section-image surf-image-4">
                </div>
            </div>
        </div>

        <app-page-selector [current]="pages.surf"></app-page-selector>

        <app-action-bar></app-action-bar>

    <app-footer style="width: 100%;"></app-footer>

</div>