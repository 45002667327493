<div class="content" role="main">

    <div class="hero-image">
        <div class="logo" (click)="logoClick()">
          <img src="/assets/images/logo_clear_black-rust_no_border.png" class="hero-logo" alt="Ferro Surf Camp logo"/>
        </div>
    </div>

    <app-hamburger></app-hamburger>

  
    <!-- Impressions -->
    <div class="impression-section">
  
      <h1 i18n style="padding: 20px; text-align: center;">waar surfen het lokale portugal ontmoet
      </h1>
<!-- PIONEER -->
<div class="section">
    <div class="section-text">
      <h1 i18n class="section-header-title">PIONEER</h1>
      <p i18n>Het ruigste en nieuwste surf camp van Portugal, waar we inzetten op een ander soort surfkamp. In de onontdekte regio Alentejo bieden wij een unieke ervaring die breder is dan de traditionele surfvakanties. 
 
        <br><br>
        Onze accommodaties, variërend van sfeervolle lemen hutjes tot gezellige omgebouwde busjes, zijn een weerspiegeling van onze toewijding aan duurzaamheid en respect voor de omgeving.
      </p>
    </div>
    <div class="section-image-container">
      <div class="section-image surf-image-3">
        <h1 i18n class="section-header-right">PIONEER</h1>
      </div>
    </div>
  </div>
        
  <div class="section">
    <div class="section-image-container">
      <div class="section-image explore-image-5">
      </div>
    </div>
    <div class="section-text">
      <p i18n>Wat ons echt onderscheidt, is onze passie voor de alentejo en het echte Portugal. Wij willen dat graag met jullie delen. In ons meer dan alleen een surf camp nemen we je daarom mee op pad naar de lokale plekjes. Kom eten bij the one and only Patricia, ga mee naar de lokale markt, waar de hele omgeving komt eten, dansen en feest vieren. kortom, leef als een local. 
        
        <br><br>
        Op deze manier leer je de mensen in de omgeving kennen, zie je hoe er hier wordt geleefd en krijg je een authentiek Portugal mee. Daarnaast bieden wij Portugese les aan, culturele uitstapjes naar wens en natuurlijk de surflessen. 
      </p>
    </div>
  </div>
  
  <app-page-selector [current]="pages.pioneer"></app-page-selector>

  <app-action-bar></app-action-bar>

    <app-footer style="width: 100%;" id="join-us"></app-footer>
  
  </div>