<div class="content" role="main">

    <div class="hero-image">
        <div class="logo" (click)="logoClick()">
          <img src="/assets/images/logo_clear_black-rust_no_border.png" class="hero-logo" alt="Ferro Surf Camp logo"/>
        </div>
    </div>

    <app-hamburger></app-hamburger>

  
    <!-- Impressions -->
    <div class="impression-section">
  
      <h1 i18n style="padding: 20px; text-align: center;">goed slapen, heerlijk eten en kleinschalig</h1>

    <!-- CAMP -->


      <div class="section">
        <div class="section-text">
          <h1 i18n class="section-header-title">CAMP</h1>
            <p i18n>Heb je weleens in een lemen hut geslapen? ontdek het nu! Naast de hutjes hebben we ook nog een aantal omgebouwde caravans en campers waar je samen met je reisgenootjes heerlijk in kan vertoeven. 
              <br><br>
              Daarnaast is er alles om heerlijk te kunnen ontspannen: natural pool, buitenbioscoop, hangmatten, bar, alles erop en eraan. Uiteraard wel allemaal met een lekker ruig randje.
            </p>
        </div>
        <div class="section-image-container">
            <div class="section-image camp-image-2">
              <h1 i18n class="section-header-right">CAMP</h1>

            </div>
          </div>
      </div>

      <div class="section">
        <div class="section-image-container">
          <div class="section-image camp-image-3">
          </div>
        </div>
        <div class="section-text">
          <p i18n>Wij zijn zelf nogal levensgenieters en groot fan van lekker eten (wie niet). vanaf de welkomst dag tot dag van vertrek wordt er een fantastisch diner en ontbijt geregeld door een van onze chefs. Voedzaam, lokaal en vooral erg lekker. 
            <br><br>
            We zullen ook een keer gaan eten bij onze vriendin Patricia in het dorp naast het kamp. Lunchen kan bij de strandtenten, op excursies en in de dorpen. Heb je dieetwensen, geef ze door! Vegan, Vega, pescotarisch, glutenvrij, het kan en mag allemaal.
          </p>
        </div>
      </div>

      <div class="section">
        <div class="section-text">
            <p i18n>Met een kleinschalige setting van ongeveer 20 gasten, bieden we een intieme en gezellige sfeer waar persoonlijke aandacht en verbondenheid centraal staan. 
              <br><br>
              Of je nu wilt ontspannen bij het kampvuur, een feestje wilt bouwen met nieuwe vrienden of wilt genieten van een heerlijke maaltijd bereid door onze getalenteerde chef-kok, bij Ferro Surf Camp voel je je direct thuis.
            </p>
        </div>
        <div class="section-image-container">
            <div class="section-image camp-image-4">

            </div>
          </div>
      </div>

      <app-page-selector [current]="pages.camp"></app-page-selector>

      <app-action-bar></app-action-bar>

    <app-footer style="width: 100%;"></app-footer>
  
  </div>