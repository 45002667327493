<div class="footer">
  <div class="footer-cover"></div>
  <div class="footer-inner">
    <div class="contact-section">
      <p i18n id="questions">vragen</p>
      <p i18n>we helpen je graag op weg</p>

      <div class="head-holder">
      <div class="head">
        <img loading="lazy" src="/assets/images/whatsapp-profile.jpeg" alt="an image of a person" />
        <p>jannah</p>
      </div>
      <div class="head">
        <img loading="lazy" src="/assets/images/tristan-profile.jpeg" alt="an image of a person" />
        <p>tristan</p>
      </div>
      <div class="head">
        <img loading="lazy" src="/assets/images/wiland-profile.jpeg" alt="an image of a person" />
        <p>wiland</p>
      </div>
    </div>

      <div class="instagram_wrapper">
        <div>
          <a href="https://instagram.com/ferrosurfcamp" target="_blank"><img loading="lazy"
              src="/assets/images/instagram_logo_white.png" width="40" height="40" alt="Instagram logo" /></a>
        </div>
        <div style="padding-left: 20px;">
          <a href="https://wa.me/+351933927560" target="_blank"><img loading="lazy" src="/assets/images/whatsapp_logo_white.png"
              width="40" height="40" alt="Whatsapp logo" /></a>
        </div>
      </div>


      <p i18n><a href="/contact">of benader ons op andere manieren</a></p><br>
    </div>
    <p style="display: inline;">ferro surf camp</p>
    <span style="color: white"> ⦁ </span>
    <a i18n href="javascript: Cookiebot.renew();" style="line-height: 2.4em; padding: 5px 0;">cookievoorkeuren</a>
    <span style="color: white"> ⦁ </span>
    <a i18n style="line-height: 2.4em; padding: 5px 0;" [href]="privacyURL" target="_blank">privacy en
      cookies</a>
    <span style="color: white"> ⦁ </span>
    <a i18n style="line-height: 2.4em; padding: 5px 0;" [href]="termsAndConditionsUrl" target="_blank">algemene
      voorwaarden</a>
    <span style="color: white"> ⦁ </span>
    <a i18n style="line-height: 2.4em; padding: 5px 0;" href="/licenses">licenties</a>
    <br>
  </div>
</div>