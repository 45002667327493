<div class="action-bar-container" [class.pop-in]="visible" [class.pop-out]="!visible">
    <div class="action-bar-book-section" (click)="window.location.href = '/#book-now'" *ngIf="withBookOption">
        <p i18n>boek nu</p>
    </div>
    <div class="action-bar-splitter-section" *ngIf="withBookOption">

    </div>
    <div class="action-bar-whatsapp-section" (click)="window.open('https://wa.me/+351933927560', '_blank')">
        <div class="whatsapp-section-text">
            <p i18n>vragen?</p>
            <p i18n style="text-decoration: underline;">whatsapp met jannah</p>
        </div>
        <div class="whatsapp-section-img">
            <img i18n-alt loading="lazy" src="/assets/images/whatsapp-profile.jpeg" alt="a girl wearing sunglasses" />
        </div>
    </div>
</div>
