<div class="content" role="main">

  <div class="hero-image">
    <video playsinline autoplay muted loop [muted]="'muted'" i18n
      style="object-fit: cover; background-size: cover; opacity: 1; width: 100%; height: 100%; position: absolute; left: 0px; top: 0px; display: block;"
      preload="auto" poster="/assets/images/banner.webp"
      aria-describedby="In this video you see multiple images of the portugues coast, people surfing, people sitting at a campfire and people driving in a jeep.">
      <source [src]="videoSource" type="video/mp4">
    </video>
    <div class="hero-video-cover">
      <div class="hero-text-container">
        <img i18n-alt src="/assets/images/logo.png" class="hero-logo" alt="Ferro Surf Camp logo" #ferrologo id="ferrologo" />
      </div>
      <div class="hero-cta">
        <p i18n id="slogan">het ruigste surfkamp van portugal</p>
        <p i18n id="slogan-subtext">ontdek het authentieke leven van de alentejo</p>

        <button class="booking-button hero-book-now" (click)="onBookHeroClick()" id="hero-book-button">
          <p i18n class="hero-book-now">boek nu</p>
        </button>
      </div>
      <img i18n-alt src="/assets/icons/arrow_down.png" id="arrow_down_icon" alt="icon arrow down" />
    </div>
  </div>

  <app-hamburger [languageSelectorWhite]="true"></app-hamburger>

  <!-- Impressions -->
  <div class="impression-section">

    <h1 i18n style="padding: 20px; text-align: center; padding-top: 40px">kleine groepen • tussen de locals • stoere verblijven</h1>

    <!-- PIONEER -->
    <div class="section">
      <div class="section-text">
        <a href="/pioneer" style="text-decoration-color: black; width: 100%;">
          <h1 i18n class="section-header-title">PIONEER</h1>
        </a>
        <p i18n>Het ruigste en nieuwste surf camp van Portugal. Bij ons draait het om <b>meer dan een surf camp</b>. Duik
          samen met ons het diepe in en leer als eerste het Ferro Surf Camp kennen. Hier maak jij als een echte local
          kennis met het authentieke Portugal, op een plek die we met veel liefde en aandacht hebben opgezet.
          <br><br>
          <a href="/pioneer">Lees hier</a> meer over wat Ferro te bieden heeft en waarom het nog vernieuwender en
          avontuurlijker maakt dan een surf camp.
        </p>
      </div>
      <div class="section-image-container">
        <div class="section-image" (click)="window.location.href = '/pioneer'">
          <img i18n-alt loading="lazy" src="/assets/images/pioneer.webp" class="section-image-inner" alt="person with surf board walking to sea" />
          <a href="/pioneer">
            <h1 i18n class="section-header-right">PIONEER</h1>
          </a>
        </div>
      </div>
    </div>

    <!-- SURF -->
    <div class="section">
      <div class="section-image-container">
        <div class="section-image" (click)="window.location.href = '/surf'">
          <img i18n-alt loading="lazy" src="/assets/images/surf2-alt.jpeg" class="section-image-inner" alt="person holding surf board pointing at the camera"/>
          <a href="/surf">
            <h1 i18n class="section-header-left">SURF</h1>
          </a>
        </div>
      </div>
      <div class="section-text">
        <a href="/surf" style="text-decoration-color: black; width: 100%;">
          <h1 i18n class="section-header-title">SURF</h1>
        </a>
        <p i18n>Heb je nog nooit op een plank gestaan, of ben je al een echte ripper? Het kan bij ons allemaal. Wij werken
          samen met een lokale surfschool en hebben veel kennis van de beste spots in de buurt.
          <br><br>
          Met het prachtige Vila Nova Milfontes op een klein stukje rijden en Sines even verderop, is er altijd een
          goede golf te vinden. Onze eigen instructeurs zullen persoonlijke begeleiding bieden in het water en op het
          droge. <a href="/surf">meer surf</a>
        </p>
      </div>
    </div>

    <!-- EXPLORE -->
    <div class="section">
      <div class="section-text">
        <a href="/explore" style="text-decoration-color: black; width: 100%;">
          <h1 i18n class="section-header-title">EXPLORE</h1>
        </a>
        <p i18n>Ben jij actief, nieuwsgierig en stap je liever buiten de gebaande paden, dan zit je bij ons aan het juiste
          adres. Spring van de plank zo de jeep in en ga mee op een van de excursies.
          <br><br>
          De Alentejo omgeving is relatief onbekend. Zonde! Wij willen graag ons enthousiasme delen over deze prachtige
          regio en laten graag het echte nog ietwat rauwe Portugal zien waar lekker eten, drinken en genieten centraal
          staat.
          <br><br>
          <a href="/explore">Bekijk</a> wat er allemaal te ontdekken valt en waarom wij jou zo graag mee op pad nemen.
        </p>
      </div>
      <div class="section-image-container">
        <div class="section-image" (click)="window.location.href = '/explore'">
          <img i18n-alt loading="lazy" src="/assets/images/explore.jpeg" class="section-image-inner" alt="a wine field in the alentejo"/>
          <a href="/explore">
            <h1 i18n class="section-header-right">EXPLORE</h1>
          </a>
        </div>
      </div>
    </div>

    <!-- CAMP -->
    <div class="section">
      <div class="section-image-container">
        <div class="section-image" (click)="window.location.href = '/camp'">
          <img i18n-alt loading="lazy" src="/assets/images/camp_hut.jpeg" class="section-image-inner" alt="an accomodation made from mud" />
          <a href="/camp" style="text-decoration-color: black;">
            <h1 i18n class="section-header-left">CAMP</h1>
          </a>
        </div>
      </div>
      <div class="section-text">
        <a href="/camp" style="text-decoration-color: black; width: 100%;">
          <h1 i18n class="section-header-title">CAMP</h1>
        </a>
        <p i18n>Het kamp ligt tussen de rode heuvels van Alentejo, en is van alle gemakken voorzien. Net een beetje anders
          dan anders is waar wij voor staan. Slaap in een van onze lemen huisjes, omgebouwde trailers en campers of in
          een van de safaritenten.
          <br><br>
          Kom lekker chillen in de natural pool of in een hangmat naast de bar. Met onze stoere jeeps brengen we je naar
          de break en alle toffe plekken die er te zien zijn. <a href="/camp">Zie hier</a> waar het kamp precies ligt en
          hoe het er uit ziet.
        </p>
      </div>
    </div>

    <!-- Booking -->
    <div class="book-section" id="join-us">
      <h1 i18n style="margin-bottom: 20px;" #joinus>JOIN US</h1>
      <p i18n class="book-text">Dit is het stoerste, authentiekste en
        vetste surfcamp van Portugal. Als jij net zo excited bent als wij om samen met ons dit avontuur te beleven, boek
        dan nu. Hieronder zetten we nog even op een rijtje wat wij allemaal bieden.</p>

      <div class="blocks-container">
        <div class="block">
          <img i18n-alt loading="lazy" src="/assets/images/pointing-at-sea.jpeg" alt="a person with a surfboard smiling" />
        </div>
        <div class="block">
          <img i18n-alt loading="lazy" src="/assets/images/jannah-op-bus.jpeg" alt="three people walking to the sea holding a surf board " />
        </div>
        <div class="block">
          <img i18n-alt loading="lazy" src="/assets/images/wiland-sunset.jpeg" alt="a person getting blinded by the sun" />
        </div>
      </div>

      <h3 i18n style="text-align: center; font-size: 1.6em;">inclusief</h3>
      <div class="book-icons-section">
        <div class="book-icons-row" (click)="window.location.href = '/camp'">
          <img i18n-alt src="/assets/icons/hot.png" width="50" height="50" alt="food icon" />
          <div style="width: 100%;" id="row-food">
            <p i18n class="book-icons-row-title">7x ontbijt en 7x diner</p>
            <p i18n class="book-icons-row-text">speciaal gemaakt door onze chefs en dorpsheld patricia</p>
          </div>
        </div>

        <div class="book-icons-row" (click)="window.location.href = '/surf'">
          <img loading="lazy" src="/assets/icons/surf.png" width="50" height="50" alt="surf icon" />
          <div style="width: 100%;" id="row-surflessons">
            <p i18n class="book-icons-row-title">5x ~2 uur surfles</p>
            <p i18n class="book-icons-row-text">inclusief wetsuit, board en persoonlijke begeleiding van lokale en onze eigen
              instructeurs
            </p>
          </div>
        </div>

        <div class="book-icons-row" (click)="window.location.href = '/camp'">
          <img i18n-alt loading="lazy" src="/assets/icons/camper-van.png" width="50" height="50" alt="camping icon" />
          <div style="width: 100%;" id="row-stay">
            <p i18n class="book-icons-row-title">7 nachten in een van onze unieke slaapverblijven</p>
            <p i18n class="book-icons-row-text">voor jou en je reisgenoten + lounge area en een goed feestje als
              je er zin in hebt</p>
          </div>
        </div>

        <div class="book-icons-row" (click)="window.location.href = '/explore'">
          <img i18n-alt loading="lazy" src="/assets/icons/wine-tasting.png" width="50" height="50" alt="wine tasting icon" />
          <div style="width: 100%;" id="row-excursions">
            <p i18n class="book-icons-row-title">vette excursies</p>
            <p i18n class="book-icons-row-text">we dompelen je helemaal onder in de lokale authentieke portugese community
            </p>
          </div>
        </div>
        <!-- <div class="book-icons-row" id="portugese-row" (click)="window.location.href = '/pioneer'">
          <img loading="lazy" src="/assets/icons/portugal.png" width="50" height="50" alt="jeep icon" />
          <div id="row-transport">
            <p class="book-icons-row-title">portugese les</p>
            <p class="book-icons-row-text">In het lokale schooltje leert onze eigen wiland je de fijne kneepjes van het
              portugees</p>
          </div>
        </div> -->

        <div class="book-icons-row" id="jeep-row">
          <img i18n-alt loading="lazy" src="/assets/icons/jeep.png" width="50" height="50" alt="jeep icon" />
          <div id="row-transport">
            <p i18n class="book-icons-row-title">vervoer in stijl</p>
            <p i18n class="book-icons-row-text">met onze wagens naar het strand en de excursies</p>
          </div>
        </div>
      </div>

      <div class="calendar-context" id="book-now" #booknow>
        <h1 i18n>BOOK NOW</h1>
        <p i18n>kies je week</p>
        <div class="calendar">
          <p i18n class="calendar-year">{{currentYear}}</p>
          <div class="month-wrapper">
            <div class="calendar-month">
              <div>
                <img i18n-alt src="/assets/icons/chevron-circle-left.png" width="20" height="20" alt="chevron left"
                  style="float: left; padding-top: 20px" (click)="previousMonth()" />
                <p class="month-title">{{getMonthByNumber(currentMonth)}}</p>
              </div>
              <div class="weekdays">
                <p *ngFor="let day of daysOfWeek">{{day}}</p>
              </div>
              <div class="days-grid">
                <p *ngFor="let item of [].constructor(getFirstWeekdayOfMonth(currentMonth, currentYear)-1)"></p>
                <p *ngFor="let item of [].constructor(daysInMonthCount(currentMonth, currentYear)); let i = index"
                  [class.date-option]="isStartDate(currentYear, currentMonth, i+1)"
                  [class.date-full]="isFullClassCheck(currentYear, currentMonth, i+1)"
                  [class.date-selected]="isSelected(currentYear, currentMonth, i+1)"
                  [class.date-selected-start]="isSelectedStart(currentYear, currentMonth, i+1)"
                  (click)="selectOption(i+1, currentMonth, currentYear)"
                  [class.date-selected-end]="isSelectedEnd(currentYear, currentMonth, i+1)">{{i+1}}</p>
              </div>
            </div>

            <div class="calendar-month">
              <div>
                <img src="/assets/icons/chevron-circle-right.png" width="20" height="20" alt="chevron right"
                  style="float: right; padding-top: 20px" (click)="nextMonth()" />
                <p class="month-title">{{getMonthByNumber(getNextMonth())}}</p>
              </div>
              <div class="weekdays">
                <p *ngFor="let day of daysOfWeek">{{day}}</p>
              </div>
              <div class="days-grid">
                <p *ngFor="let item of [].constructor(getFirstWeekdayOfMonth(getNextMonth(), getNextYear())-1)"></p>
                <p *ngFor="let item of [].constructor(daysInMonthCount(getNextMonth(), getNextYear())); let i = index"
                  [class.date-option]="isStartDate(getNextYear(), getNextMonth(), i+1)"
                  [class.date-full]="isFullClassCheck(getNextYear(), getNextMonth(), i+1)"
                  [class.date-selected]="isSelected(getNextYear(), getNextMonth(), i+1)"
                  [class.date-selected-start]="isSelectedStart(getNextYear(), getNextMonth(), i+1)"
                  [class.date-selected-end]="isSelectedEnd(getNextYear(), getNextMonth(), i+1)"
                  (click)="selectOption(i+1, getNextMonth(), getNextYear())">{{i+1}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="booking-specifics">
          <div>
            <p i18n>Voor hoeveel personen wil je boeken?</p>
            <div class="amount-selector">
              <div class="amount-option" *ngFor="let amount of amountOfPeople"
                [class.amount-selected]="amount == people" (click)="changeAmountOfPeople(amount)">
                <p>{{amount}}</p>
              </div>
            </div>
          </div>

          <div style="margin: auto;">
            <p i18n style="padding-top: 14px; font-size: 1.2em">totaal: <span
                style="font-weight: 500;">€{{getPrice()}}</span></p>
          </div>

        </div>

      </div>
      <button class="booking-button begin-checkout" (click)="onBookClick()" [disabled]="selectedWeek == null"
        id="book-button">
        <p i18n class="begin-checkout">boeken</p>
      </button>

      <!-- Booking -->
      <div class="book-section">
        <div class="book-icons-section">
          <h1 i18n style="padding-bottom: 20px; padding-top: 40px; font-size: 1.4em; ">VEELGESTELDE VRAGEN</h1>

          <div class="faq-row" (click)="surfSubtext = !surfSubtext">
            <div id="row-surflessons">
              <p i18n class="faq-row-title">hoe kom ik bij het kamp?</p>
            </div>
            <p><span *ngIf="!surfSubtext" class="material-symbols-outlined expand-icon">
                expand_more
              </span>
              <span *ngIf="surfSubtext" class="material-symbols-outlined expand-icon">
                expand_less
              </span>
            </p>
          </div>
          <p i18n *ngIf="surfSubtext" class="faq-subtext">Met het vliegtuig: Vlieg naar Lissabon of Faro, wij liggen daar
            precies tussenin! Vanaf daar kan je een airport transfer nemen die je direct naar het kamp brengt! Je kan
            deze later bij ons boeken, zodat we de busjes goed kunnen indelen. Dat is voordeliger en gezelliger! Reken
            op een bedrag tussen de €70 en €100.
            <br><br>
            Per auto: Vanuit Nederland is het ongeveer 2300 km rijden. Ons advies is om de tolwegen te vermijden. Qua
            afstand scheelt dat niks, qua geld ongeveer €120 en qua uitzicht scheelt het alles. Zet je reis op blablacar
            en verdien er geld mee.
          </p>


          <div class="faq-row" (click)="accomodationSubtext = !accomodationSubtext">
            <div id="row-stay">
              <p i18n class="faq-row-title">kan ik een specifiek verblijf boeken?</p>
            </div>
            <p><span *ngIf="!accomodationSubtext" class="material-symbols-outlined expand-icon">
                expand_more
              </span>
              <span *ngIf="accomodationSubtext" class="material-symbols-outlined expand-icon">
                expand_less
              </span>
            </p>
          </div>
          <p i18n *ngIf="accomodationSubtext" class="faq-subtext">Een van de dingen die ons kamp uniek maakt, zijn onze
            verschillende verblijven. Lemen huisjes, omgebouwde busjes, caravans en speciale tenten (geen tipi’s). Wij
            delen deze verblijven random in, waardoor er een kans bestaat dat je niet in je favoriete verblijf
            overnacht. Elk verblijf heeft een eigen karakter en is altijd comfortabel met een goed bed. We hebben er
            vertrouwen in dat je daarom in elk verblijf tevreden bent. Mocht je toch graag in een ander verblijf willen,
            dan gaan we kijken wat we kunnen regelen.
          </p>

          <div class="faq-row" (click)="lunchSubtext = !lunchSubtext">
            <div id="row-stay">
              <p i18n class="faq-row-title">waarom is lunch niet inclusief?
              </p>
            </div>
            <p><span *ngIf="!lunchSubtext" class="material-symbols-outlined expand-icon">
                expand_more
              </span>
              <span *ngIf="lunchSubtext" class="material-symbols-outlined expand-icon">
                expand_less
              </span>
            </p>
          </div>
          <p i18n *ngIf="lunchSubtext" class="faq-subtext">Lunchen is heilig in Portugal en wordt daarom groots aangepakt. De
            hele omgeving stopt met werken en iedereen geniet van de lekkernijen die Alentejo te bieden heeft. Wij zijn
            groot fan van alle cafés en restaurants in de dorpen en het strand en hier eten hoort bij de ervaring van
            Portugal die wij jullie willen meegeven. Wij zorgen dat je bij deze spots terechtkomt. Wil je liever een
            broodje op het strand? Dan maken we een lunchpakket voor je in de ochtend die je kan meenemen.
          </p>

          <div class="faq-row">
            <div style="width: 100%;" id="row-stay">
              <p i18n class="faq-row-title"><a href="https://wa.me/+351933927560" target="_blank">mis je een vraag? laat het
                  ons weten</a></p>
            </div>
          </div>

        </div>

        <div style="height: 40px;"></div>
      </div>
    </div>

    <app-action-bar [visible]="bookNowVisible" [withBookOption]="!atBookNow"></app-action-bar>

    <app-footer style="width: 100%;"></app-footer>

  </div>