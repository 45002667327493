<div class="content" role="main">

    <div class="hero-image">
        <div class="logo" (click)="logoClick()">
          <img src="/assets/images/logo_clear_black-rust_no_border.png" class="hero-logo" alt="Ferro Surf Camp logo"/>
        </div>
    </div>

    <app-hamburger></app-hamburger>

  
    <!-- Impressions -->
    <div class="impression-section">

      <h1 i18n style="padding: 20px; text-align: center;">een regio naar ons hart: de alentejo
      </h1>
  
     <!-- EXPLORE -->
     <div class="section">
        <div class="section-text">
          <h1 i18n class="section-header-title">EXPLORE</h1>
          <p i18n>Op avontuur in de Alentejo, hoe vet is dat. Wijn proeven, kajakken, wijn proeven, bushcraften, wijn proeven, sardientjes grillen terwijl je wijn proeft. Houd je niet zo van wijn proeven? Geen probleem, wij wel heel erg, maar het is niet het enige wat we gaan doen. 
            <br><br>
            De Alentejo regio biedt allerlei avonturen in de grote natuurparken, de klifkusten en de vele pittoreske dorpjes. Iedereen kan dus op eigen ontdekkingstocht.
          </p>
        </div>
        <div class="section-image-container">
          <div class="section-image explore-image-2">
            <h1 i18n class="section-header-right">EXPLORE</h1>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="section-image-container">
            <div class="section-image explore-image-3">
            </div>
          </div>
        <div class="section-text">
          <p i18n>      De Alentejo regio is de grootste regio van Portugal en relatief onontdekt. Tussen Lissabon en de Algarve, wordt hier vooral van het land geleefd. Van kust tot de grens met Spanje, in deze uitgestrekte regio kan men wijn, vee, en vooral authentieke mensen vinden. Het leven en delen in de community is van groot belang in dit gebied. 
            <br><br>
            Waar infrastructuur en gemakken soms wat achterwege laten, is het in de Alentejo van groot belang dat men elkaar ondersteunt. Dit betekent wat terug doen voor de vergrijzende bevolking, en vooral veel terugkrijgen in kennis van het land, de cultuur en de liefde voor de regio. 
          </p>
        </div>
      </div>

      <div class="section">
        
        <div class="section-text">
          <p i18n>      Onze excursies, die zijn inbegrepen in de prijs van het verblijf, nemen je mee op een reis door de rijke geschiedenis en natuurlijke schoonheid van de regio. 
            <br><br>
            Van wijnproeverijen tot boottochten op de Mira-rivier en wandelingen langs de beroemde Fishermen's Trail, we zorgen ervoor dat je de hele week ondergedompeld wordt in de unieke cultuur en charme van Alentejo.
          </p>
        </div>
        <div class="section-image-container">
          <div class="section-image explore-image-4">
          </div>
        </div>
      </div>
    
      <app-page-selector [current]="pages.explore"></app-page-selector>

      <app-action-bar></app-action-bar>

    <app-footer style="width: 100%;"></app-footer>
  
  </div>