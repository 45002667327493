import { Component } from '@angular/core';

@Component({
  selector: 'app-navigation-blocks',
  templateUrl: './navigation-blocks.component.html',
  styleUrls: ['./navigation-blocks.component.scss']
})
export class NavigationBlocksComponent {

}
