import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    private url = environment.apiUrl

    private availabilityURL = "/availability"
    private bookURL = "/book"
    private bookingURL = "/booking"
    private statusURL = "/status"
    private newPayLinkURL = "/new-pay-link"
    private transfersURL = "/transfers"

  constructor(private http: HttpClient) { }

  getAvailability() {
    return this.http.get(this.url+this.availabilityURL);
  }

  getAvailabilityDetail(id: string) {
    return this.http.get(this.url+this.availabilityURL+'/'+id);
  }

  getTransfers() {
    return this.http.get(this.url+this.transfersURL);
  }

  book(name: string, email: string, phone: string, address: string, city: string, postal: string, amountOfBookings: number, availabilityJson: any, transferID: string){
    let body = {
      name: name,
      email: email,
      phone: phone,
      address: address,
      city: city,
      postal: postal,
      amount_of_bookings: amountOfBookings,
      availability: availabilityJson,
      transfer_id: transferID,
    }
    return this.http.post(this.url+this.bookURL, body);
  }

  getBookingStatus(id: string) {
    return this.http.get(this.url+this.bookingURL+'/'+id+this.statusURL);
  }

  getNewPayLink(bookingId: string) {
    return this.http.get(this.url+this.newPayLinkURL+'/'+bookingId);
  }
}