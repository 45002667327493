<div class="content" role="main">
    <div class="container">
        <div class="hero-image">
            <div class="hero-text-container" (click)="home()">
              <img src="/assets/images/logo_clear_black-rust_no_border.png" class="hero-logo" alt="Ferro Surf Camp logo"/>
            </div>
        </div>

        <!-- STATUS == PAID -->
        <div class="success-section" *ngIf="status == 'paid'">
          <h2 i18n id="success-title">Gelukt</h2>
          <p i18n id="success-msg">Jouw boeking is officieel bevestigd! Je hebt een fantastische week voor de boeg! Ontspan, geniet van de prachtige omgeving en laat je meeslepen door de opwindende wereld van het surfen en de Alentejo.<br><br>            
            
            Je ontvangt binnenkort een bevestigingsmail met alle details. Heb je vragen? Neem gerust contact met ons op via onze <a href="https:instagram.com/ferrosurfcamp">sociale kanalen</a>. <br>
          
            <br><br>
           <!-- Terwijl je geduldig wacht, waarom niet alvast een voorproefje nemen van wat er komen gaat? Bekijk alvast de omgeving en excursies, of ga in de never-ending rabbit hole van surf filmpjes. -->
            </p>

            <div>
              <input type="hidden" value="{{googlePurchaseEventItem.transaction_id}}" id="transaction_id" />
              <input type="hidden" value="{{googlePurchaseEventItem.value}}" id="value" />
              <input type="hidden" value="{{googlePurchaseEventItem.currency}}" id="currency" />
              <input type="hidden" value="{{googlePurchaseEventItem.date}}" id="date" />
              <input type="hidden" value="{{googlePurchaseEventItem.email}}" id="email" />
              <input type="hidden" value="{{googlePurchaseEventItem.items[0].item_id}}" id="item_id" />
              <input type="hidden" value="{{googlePurchaseEventItem.items[0].item_name}}" id="item_name" />
              <input type="hidden" value="{{googlePurchaseEventItem.items[0].price}}" id="price" />
              <input type="hidden" value="{{googlePurchaseEventItem.items[0].quantity}}" id="quantity" />

            </div>

            <script type="text/javascript">
              function sendPurchaseEvent() {
                console.log("send purchase event");
                gtag("event", "purchase", {
                  transaction_id: document.getElementById('transaction_id').value,
                  value: document.getElementById('value').value,
                  currency: document.getElementById('currency').value,
                  date: document.getElementById('date').value,
                  email: document.getElementById('email').value,
                  items: [{
                    item_id: document.getElementById('item_id').value,
                    item_name: document.getElementById('item_name').value,
                    price: document.getElementById('price').value,
                    quantity: document.getElementById('quantity').value
                  }],
                });
            }
            </script>
        </div>

        
       

        <!-- STATUS == OPEN -->
        
        <!-- STATUS == CANCELED -->
        <div class="success-section" *ngIf="status == 'canceled'">
          <h2 i18n id="success-title">Daar ging iets mis</h2>
          <p i18n id="success-msg">De betaling is geannuleerd. Probeer het snel opnieuw!</p>
          <button class="new-pay-button" (click)="tryAgain()">
            <p i18n style="color: #ffffff">Opnieuw proberen</p>
          </button>
        </div>
        
        <!-- STATUS == PAID or CANCELED -->
       <!-- <div class="explore-section" *ngIf="status?.status == 'paid' || status?.status == 'canceled'">
          <div class="explore-card" id="excursions">
            <div class="explore-card-inner">
              <p>EXCURSIONS</p>
            </div>
          </div>
          <div class="explore-card" id="alentejo">
            <div class="explore-card-inner">
              <p>ALENTEJO</p>
            </div>
          </div>
          <div class="explore-card" id="surf">
            <div class="explore-card-inner">
              <p>SURF</p>
            </div>
          </div>
        </div> -->

        <!-- STATUS == PENDING -->
        <div class="success-section" *ngIf="status == 'pending'">
          <h2 i18n id="success-title">Hang loose</h2>
          <p i18n id="success-msg">Onze betaalpartner ligt zelf nog op het strand. Nog even wachten tot we de 'oké' binnen hebben.<br>...</p>
        </div>

        <!-- STATUS == EXPIRED -->
        <div class="success-section" *ngIf="status == 'expired'">
          <h2 i18n id="success-title">Take your time</h2>
          <p i18n id="success-msg">Zit je zelf al op het strand? De betaallink is namelijk verlopen! No worries, probeer het hieronder opnieuw.</p>
          <button class="new-pay-button" (click)="tryAgain()">
            <p i18n style="color: #ffffff">Opnieuw proberen</p>
          </button>
        </div>
        
        <!-- STATUS == FAILED -->
        <div class="success-section" *ngIf="status == 'failed'">
          <h2 i18n id="success-title">Mislukt</h2>
          <p i18n id="success-msg">Verdorie. De betaling ging de mist in. Probeer het snel opnieuw.</p>
          <button class="new-pay-button" (click)="tryAgain()">
            <p i18n style="color: #ffffff">Opnieuw proberen</p>
          </button>
        </div>

        <app-footer style="width: 100%;"></app-footer>
    </div>    
</div>
