<div class="page-selector-container">
    <div class="page pioneer" *ngIf="current != pages.pioneer" (click)="window.location.href = '/pioneer'">
        <div class="overlay">
            <p i18n>pioneer</p>
        </div>
    </div>
    <div class="page surf" *ngIf="current != pages.surf" (click)="window.location.href = '/surf'">
        <div class="overlay">
            <p i18n>surf</p>
        </div>
    </div>
    <div class="page explore" *ngIf="current != pages.explore" (click)="window.location.href = '/explore'">
        <div class="overlay">
            <p i18n>explore</p>
        </div>
    </div>
    <div class="page camp" *ngIf="current != pages.camp" (click)="window.location.href = '/camp'">
        <div class="overlay">
            <p i18n>camp</p>
        </div>
    </div>
</div>

<div class="end-page-cta-container">
    <p i18n class="book-title">de boekingen voor zomer 2024 zijn open</p>
    <button class="booking-button hero-book-now" (click)="window.location.href = '/#join-us'">
            <p i18n class="hero-book-now">nu boeken</p>
      </button>
</div>
