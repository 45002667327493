import { Component, Input } from '@angular/core';

export enum pages { pioneer, surf, explore, camp }

@Component({
  selector: 'app-page-selector',
  templateUrl: './page-selector.component.html',
  styleUrls: ['./page-selector.component.scss']
})
export class PageSelectorComponent {
  @Input() current: pages = pages.pioneer;

  public window = window;
  public pages = pages;
}
