<div class="hamburger" (click)="openHamburger()">
    <button class="hamburger-button">
      <p><span class="material-symbols-outlined hamburger-icon">
        menu
        </span></p>
    </button>
  </div>

  <div class="language-selector hide-on-mobile">
    <a class="black" [class.white]="languageSelectorWhite" *ngIf="window.location.href.indexOf('ferrosurfcamp.nl') > 0" href="https://ferrosurfcamp.com">switch to english</a>
    <a class="black" [class.white]="languageSelectorWhite" *ngIf="window.location.href.indexOf('ferrosurfcamp.com') > 0 || window.location.href.indexOf('localhost') > 0" href="https://ferrosurfcamp.nl">switch to dutch</a>
  </div>

  <div class="menu-overlay" [class.open]="open" [class.closed]="!open">
    <div class="hamburger" (click)="openHamburger()">
      <button class="hamburger-button">
        <p><span class="material-symbols-outlined close-icon">
          close
          </span></p>
      </button>
    </div>

    <div class="language-selector">
      <a class="black" *ngIf="window.location.href.indexOf('ferrosurfcamp.nl') > 0" href="https://ferrosurfcamp.com">switch to english</a>
      <a class="black" *ngIf="window.location.href.indexOf('ferrosurfcamp.com') > 0 || window.location.href.indexOf('localhost') > 0" href="https://ferrosurfcamp.nl">switch to dutch</a>
    </div>

    <div class="menu">
      <a i18n href="/pioneer"><p class="menu-item">PIONEER</p></a>
      <a i18n href="/surf"><p class="menu-item">SURF</p></a>
      <a i18n href="/explore"><p class="menu-item">EXPLORE</p></a>
      <a i18n href="/camp"><p class="menu-item">CAMP</p></a>
      <a i18n href="/crew"><p class="menu-item">CREW</p></a>
    </div>
  </div>