import { Component, OnInit, inject, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '../services/http.service'

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  private httpService = inject(HttpService);

  status: any = null
  googlePurchaseEventItem: any;

  constructor(private route: ActivatedRoute, private router: Router, private renderer: Renderer2) {}

  ngOnInit() {
    if(this.route.snapshot.paramMap.get('id') == (null || undefined)) {
      this.home()
      return
    }

    setTimeout(() => {
      this.getBookingStatus()
    }, 300);
  }

  getBookingStatus() {
    let id = this.route.snapshot.paramMap.get('id')!!
    this.httpService.getBookingStatus(id).subscribe((res: any) => {
      this.status = res.status;
      this.googlePurchaseEventItem = res;
      this.statusCheck();
    })
  }

  statusCheck() {
    if (this.status == "pending") {
      setTimeout(() => {
        this.getBookingStatus()
      }, 2000);
    } else if (this.status == "paid") {
      this.sendGTMPurchaseEvent()
    }
  }

  sendGTMPurchaseEvent(){
    this.triggerJavaScriptFunction();
    // gtag("event", "purchase", this.googlePurchaseEventItem!!);
  }

  triggerJavaScriptFunction() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      console.log("send purchase event");
      gtag("event", "purchase", {
        transaction_id: "${this.googlePurchaseEventItem.transaction_id}",
        value: ${this.googlePurchaseEventItem.value},
        currency: "${this.googlePurchaseEventItem.currency}",
        date: "${this.googlePurchaseEventItem.date}",
        email: "${this.googlePurchaseEventItem.email}",
        items: [{
          item_id: "${this.googlePurchaseEventItem.items[0].item_id}",
          item_name: "${this.googlePurchaseEventItem.items[0].item_name}",
          price: ${this.googlePurchaseEventItem.items[0].price},
          quantity: ${this.googlePurchaseEventItem.items[0].quantity}
        }],
      });`; 
  
    // Append the script to the body or another element
    this.renderer.appendChild(document.body, script);
  }

  tryAgain() {
    this.httpService.getNewPayLink(this.route.snapshot.paramMap.get('id')!!).subscribe(res => {
      window.location.href = res.toString()
    })
  }

  home() {
    this.router.navigate([''])
  }
}
