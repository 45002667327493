import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss']
})
export class HamburgerComponent {
  @Input() languageSelectorWhite: boolean = false;

  open = false;
  public window = window;

  openHamburger() {
    this.open = !this.open;
  }

}
