<div class="content" role="main">
    <div style="width: 100%; height: 100%;">
        <div class="hero-image">
            <div class="hero-text-container" (click)="home()">
                <img src="/assets/images/logo_clear_black-rust_no_border.png" class="hero-logo"
                    alt="Ferro Surf Camp logo" />
            </div>
        </div>
        <div class="flex-container">
            <!-- Booking -->
            <div class="book-section">
                <p class="book-text" i18n>Leuk dat je bij ons Portugal komt ontdekken! Van de wijntjes van de Alentejo tot de adembenemende zee. Hieronder staat op een rijtje waarvoor je betaalt. Voor je boeking hebben we je gegevens nodig. Dan kan je direct door naar betalen! </p>

                <h3 i18n style="font-size: 1.6em; padding: 20px 40px;">inclusief</h3>
                <div class="book-icons-section">
                    <div class="book-icons-row">
                        <img src="/assets/icons/hot.png" width="50" height="50" alt="food icon" />
                        <div style="width: 100%;" id="row-food">
                            <p i18n class="book-icons-row-title">7x ontbijt en 7x diner</p>
                            <p i18n class="book-icons-row-text">speciaal gemaakt door onze chefs en dorpsheld patricia</p>
                        </div>

                    </div>


                    <div class="book-icons-row">
                        <img src="/assets/icons/surf.png" width="50" height="50" alt="surf icon" />
                        <div style="width: 100%;" id="row-surflessons">
                            <p i18n class="book-icons-row-title">5x ~2 uur surfles</p>
                            <p i18n class="book-icons-row-text">inclusief wetsuit, board en persoonlijke begeleiding van
                                lokale
                                en onze eigen instructeurs
                            </p>
                        </div>
                    </div>

                    <div class="book-icons-row">
                        <img src="/assets/icons/camper-van.png" width="50" height="50" alt="camping icon" />
                        <div style="width: 100%;" id="row-stay">
                            <p i18n class="book-icons-row-title">7 nachten in een van onze unieke slaapverblijven</p>
                            <p i18n class="book-icons-row-text">voor jou en je reisgenoten + lounge area en een goed feestje
                                als
                                je er zin in hebt</p>
                        </div>
                    </div>

                    <div class="book-icons-row">
                        <img src="/assets/icons/wine-tasting.png" width="50" height="50" alt="wine tasting icon" />
                        <div style="width: 100%;" id="row-excursions">
                            <p i18n class="book-icons-row-title">vette excursies</p>
                            <p i18n class="book-icons-row-text">we dompelen je helemaal onder in de lokale authentieke
                                portugese
                                community</p>
                        </div>
                    </div>
                    <!-- <div class="book-icons-row" id="portugese-row">
                        <img src="/assets/icons/portugal.png" width="50" height="50" alt="jeep icon" />
                        <div id="row-transport">
                            <p class="book-icons-row-title">portugese les</p>
                            <p class="book-icons-row-text">In het lokale schooltje leert onze eigen wiland je de fijne
                                kneepjes van het portugees</p>
                        </div>
                    </div> -->

                    <div class="book-icons-row" id="jeep-row">
                        <img src="/assets/icons/jeep.png" width="50" height="50" alt="jeep icon" />
                        <div id="row-transport">
                            <p i18n class="book-icons-row-title">vervoer in stijl</p>
                            <p i18n class="book-icons-row-text">met onze wagens naar het strand en de excursies</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form">
                <form class="booking-form">
                    <h1>{{getDateLabel()}}</h1>

                    <input i18n-placeholder type="text" placeholder="naam" [(ngModel)]="name" name="name" />
                    <input i18n-placeholder type="email" placeholder="email" [(ngModel)]="email" name="email" />
                    <input i18n-placeholder type="text" placeholder="telefoon" [(ngModel)]="phone" name="phone" />
                    <input i18n-placeholder type="text" placeholder="straat + nummer" [(ngModel)]="address" name="address" />
                    <input i18n-placeholder type="text" placeholder="stad" [(ngModel)]="city" name="city" />
                    <input i18n-placeholder type="text" placeholder="postcode" [(ngModel)]="postal" name="postal" />

                    <div class="persons-section">
                        <p i18n>personen: </p>
                        <div class="persons-amount">
                            <p>{{persons}} </p>
                        </div>
                        <p style="margin-left: auto;">€{{price*persons}},-</p>
                    </div>
                    <h2 i18n style="margin-left: auto;">totaal: €{{price*persons+(choosenTransfer?.data.price || 0)}},-
                    </h2>

                    <div style="padding-top: 8px; display: flex; flex-direction: row; align-items: center;">
                        <input type="checkbox" style="margin-right: 8px;" [checked]="termsAccepted"
                            (change)="termsAccepted = !termsAccepted">
                        <p i18n>Ik ga akkoord met de <a [href]="termsAndConditionsUrl" target="_blank">algemene
                                voorwaarden</a></p>
                    </div>

                    <button class="pay-button start-payment" (click)="onPayClick()" [disabled]="!formCompleted()">
                        <p i18n style="color: #ffffff" class="start-payment">betalen</p>
                    </button>
                    <div style="height: 60px;"></div>
                </form>
            </div>
        </div>
    </div>

    <app-action-bar [withBookOption]="false" ></app-action-bar>
</div>