<div class="content" role="main">

    <div class="hero-image">
        <div class="logo" (click)="logoClick()">
          <img src="/assets/images/logo_clear_black-rust_no_border.png" class="hero-logo" alt="Ferro Surf Camp logo"/>
        </div>
        <div class="hero-text-container">
          <h1 i18n class="hero-text">LICENTIES</h1>
        </div>
    </div>
  
  
    <!-- Impressions -->
    <div class="contact-section">
  
      <div class="section">
        <p i18n>
            <a href="https://www.flaticon.com/free-icons" title="icons">icons created by freepik & smashicons - flaticon</a>
            </p>

      </div>    
    </div>
  
    <app-footer style="width: 100%;"></app-footer>
  </div>